export async function AddSportgren(parent_id, name ,type){
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/sportgren/do_submit/" + parent_id, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "add_sportgren",
		"db_type": type,
    "db_name": name,
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}