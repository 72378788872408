export async function EditSportclass(sportclass_id, form ,type){
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/sportclass/do_submit/" + sportclass_id, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "edit_sportclass",
		"db_def_klass_typer_id": type,
    "db_name": form.name,
		"db_age_min": form.age_min,
		"db_age_max": form.age_max,
		"db_gender": form.gender,
		"db_results_min": form.results_min,
		"db_results_max": form.results_max,
		"db_results_to_count": form.results_to_count,
		"db_sort_order": 10
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}