import React, { useState } from 'react';
import {    
	MDBRow,
	MDBCol,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBBtn,
	MDBAlert,
	MDBInput,
	MDBValidation,
	MDBValidationItem,
	MDBSelect
 } from 'mdb-react-ui-kit';
import { AddSportclass } from '../../sportgrenar/service/AddSportclass';

export default function AddSportclassModal(params){

	const sport = params.sport;
	const [sportclassAddedToast, setSportclassAddedToast] = useState(false)
	const [sportclassErrorToast, setSportclassErrorToast] = useState(false)
	const [formValue, setFormValue] = useState({
    name: '',
		age_min: '',
		age_max: '',
		gender: 'MK',
		results_min: '',
		results_max: '',
		results_to_count: ''
  });

	const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

	const selectChange = (e) => {
    setFormValue({ ...formValue, ["gender"]: e.value });
  };

	function handleSubmit(e){
    e.preventDefault();
    if(e.target.checkValidity()){
			AddSportclass(sport.value,formValue,30).then(obj => {
				if(obj.status === "OK"){
					setSportclassAddedToast(true)
				} else {
					setSportclassErrorToast(true)
				}
			});
			
    } 
  };

	return <>
					<MDBAlert
						color='primary'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassAddedToast}
						onClose={() => setSportclassAddedToast(false)}
					>
						Klass har lagts till för {sport.name}.
					</MDBAlert>
					<MDBAlert
						color='danger'
						autohide
						position='top-right'
						delay={4000}
						appendToBody
						open={sportclassErrorToast}
						onClose={() => setSportclassErrorToast(false)}
					>
						Klass kunde inte läggas till.
					</MDBAlert>
					<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
        		<MDBModalDialog size="lg">
							<MDBValidation onSubmit={handleSubmit}>
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Lägg till klass</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody>
										<MDBRow className='mb-5'>
											<MDBValidationItem className='col-md-4' feedback='Fyll i ett namn' invalid>
												<MDBInput
													value={formValue.name}
													name='name'
													onChange={onChange}
													required
													label='Klassnamn'
												>
													<div className='form-helper'>
														Namn på klass
													</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.age_min}
													name='age_min'
													onChange={onChange}
													required
													type='number'
													label='Min ålder'
													min={1}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Välj mellan 1-100
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.age_max}
													name='age_max'
													onChange={onChange}
													required
													type='number'
													label='Max ålder'
													min={1}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Välj mellan 1-100
												</div>
												</MDBInput>
											</MDBValidationItem>
										</MDBRow>
										<MDBRow className='mb-2'>
											<MDBValidationItem className='col-md-3'>
												<MDBSelect
														value={formValue.gender}
														label='Kön'
														data={[
															{ text: 'Kvinna/Man', value: "MK" },
															{ text: 'Kvinna', value: "K" },
															{ text: 'Man', value: "M" },
														]}
														name='gender'
														onChange={selectChange}			
														required											
													>
												</MDBSelect>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_min}
													name='results_min'
													onChange={onChange}
													required
													type='number'
													label='Min resultat'
													min={0}
													max={9999}
													step={1}
												>
												<div className='form-helper'>
													Lägsta resultat
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_max}
													name='results_max'
													onChange={onChange}
													required
													type='number'
													label='Max resultat'
													min={0}
													max={9999}
													step={1}
												>
												<div className='form-helper'>
													Högsta resultat
												</div>
												</MDBInput>
											</MDBValidationItem>
											<MDBValidationItem className='col-md-3'>
												<MDBInput
													value={formValue.results_to_count}
													name='results_to_count'
													onChange={onChange}
													required
													type='number'
													label='Antal resultat'
													min={1}
													max={100}
													step={1}
												>
												<div className='form-helper'>
													Antal resultat som räknas
												</div>
												</MDBInput>
											</MDBValidationItem>
										</MDBRow>
									</MDBModalBody>
							<MDBModalFooter>
								<MDBBtn color='secondary' onClick={params.close}>
									Stäng
								</MDBBtn>
								<MDBBtn color='primary' type='submit'>
									Lägg till
								</MDBBtn>
							</MDBModalFooter>
						</MDBModalContent>
					</MDBValidation>
        </MDBModalDialog>
      </MDBModal>
					
        </>
  
}