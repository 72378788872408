export async function EditSportgren(id, name){
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/sportgren/do_submit/" + id, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "edit_sportgren",
    "db_name": name,
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}