import React,{ useMemo, useState, useEffect } from 'react';
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBBtn,
	MDBSwitch
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_ExpandAllButton
} from 'material-react-table';
import {
	Box,
	MenuItem,
	Stack
} from '@mui/material';
import { fetch_get } from "../../../../services/common";
import AddSportgrenModal from './AddSportgrenModal';
import EditSportclassModal from './EditSportclassModal';
import EditSportgrenModal from './EditSportgrenModal';
import AddSportclassModal from './AddSportclassModal';

export default function KansliEventClass() {

	const [data,setData] = useState([])
	const [addSportgrenModal, setAddSportgrenModal] = useState(false)
	const [addClassModal, setAddClassModal] = useState(false)
	const [editSportgrenModal, setEditSportgrenModal] = useState(false)
	const [editClassModal, setEditClassModal] = useState(false)
	const [selectedSport, setSelectedSport] = useState(null)
	const [sportDepth, setSportDepth] = useState(null)
	const [selectedClass, setSelectedClass] = useState(null)
	const [fetchAll, setFetchAll] = useState(false)
	const [loading, setLoading] = useState(false)
	const [refreshTable, setRefreshTable] = useState(false)

	useEffect(() => {
		setLoading(true)
		const fetch_string = fetchAll ? "app/list/sportgrenar-tree" : "app/list/sportgrenar-tree-active"
		fetch_get(fetch_string)
		.then((data) =>
			setTimeout(() => {
				setData(data.data);
				setLoading(false)
			}, 3000)
		);
  }, [fetchAll, refreshTable]);

	useEffect(() => {
		if(!addSportgrenModal && !addClassModal && !editSportgrenModal && !editClassModal)
		setRefreshTable(!refreshTable)
  }, [addSportgrenModal, addClassModal, editSportgrenModal, editClassModal]);

	const toggleAddSportgrenModal = (sport,depth) => {		
		setSportDepth(depth)
		setSelectedSport(sport)
		setAddSportgrenModal(!addSportgrenModal)
	};

	const toggleEditSportgrenModal = (sport,depth) => {		
		setSportDepth(depth)
		setSelectedSport(sport)
		setEditSportgrenModal(!editSportgrenModal)
	};

	const toggleAddClassModal = (sportClass) => {		
		setSelectedClass(sportClass)
		setAddClassModal(!addClassModal)
	};

	const toggleEditClassModal = (sportClass) => {		
		setSelectedClass(sportClass)
		setEditClassModal(!editClassModal)
	};

	function padString(depth, val){
		switch(depth){
			case 0:
				return <p className="m-0 text-primary"> 
								{val}						
							</p>
			case 1:
				return <p className="ps-1 mx-1 mb-0 text-success"> 
									{val}						
								</p>
			case 2:
				return <p className="ps-2 mx-2 mb-0 text-info"> 
									{val}		
								</p>
			case 3:
				return <p className="ps-3 mx-3 mb-0 text-danger"> 
									{val}		
								</p>
			case 4:
				return <p className="ps-4 mx-4 mb-0"> 
									{val}		
								</p>
			default:
				return <p className="ps-4 mx-4 mb-0"> 
									{val}		
								</p>
		}
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'name',
        header: 'Namn',
        size: 200,
				Cell: ({ cell,row }) => (
					padString(row.depth,cell.getValue())
				)
      },
			{
        accessorKey: 'age_min',
        header: 'Ålder min',
        size: 30
      },
			{
        accessorKey: 'age_max',
        header: 'Ålder max',
        size: 30
      },
			{
        accessorKey: 'gender',
        header: 'Kön',
        size: 50
      },
			{
        accessorKey: 'results_min',
        header: 'Resultat min',
        size: 30
      },
			{
        accessorKey: 'results_max',
        header: 'Resultat max',
        size: 30
      },
			{
        accessorKey: 'results_to_count',
        header: 'Resultat att räkna',
        size: 30
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
			columnPinning: {
        right: ['mrt-row-actions'],
      },
			density: 'compact'
		},
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
		enableColumnActions: false,
		enableExpandAll: false,
    enableExpanding: true,
		enableSorting: false,
    filterFromLeafRows: true,
		groupedColumnMode: false,
		enableRowActions: true,
		state: {isLoading:loading},
		displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
          </Stack>
        ),
      },
    },    
		renderRowActionMenuItems: ({ row,closeMenu }) => 
			row.depth === 4 ?
				[
				<MenuItem key="edit" onClick={() => (toggleEditClassModal(row.original), closeMenu())}>
					Hantera klass
				</MenuItem>,
			] : [
				<MenuItem key="add" onClick={() => ((row.depth === 3 ? toggleAddClassModal(row.original) : toggleAddSportgrenModal(row.original, row.depth)) , closeMenu())}>
					Lägg till {row.depth === 0 ? "gren" : row.depth === 1 ? "disciplin" : row.depth === 2 ? "tävlingstyp" : row.depth === 3 ? "klass" : null}
				</MenuItem>,
				<MenuItem key="edit" onClick={() => (toggleEditSportgrenModal(row.original, row.depth), closeMenu())}>
					Hantera {row.depth === 0 ? "grenkommitté" : row.depth === 1 ? "gren" : row.depth === 2 ? "disciplin" : row.depth === 3 ? "tävlingstyp" : null}
				</MenuItem>,
			],
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: row.original.status === "DISABLED" ? '#f77d74' : row.depth === 4 ? '#f7faff' : null
			},
		}),
		getSubRows: (row) => row.children,
		enableFullScreenToggle: false,
		localization:MRT_Localization_SV,
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'end',
          }}
        >
					<Box className="me-4">
						<MDBSwitch id='flexSwitchCheckDefault' onChange={() => setFetchAll(!fetchAll)} label='Inkludera inaktiverade klasser' />
					</Box>
        </Box>

      );
		},
		renderBottomToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
					<MDBBtn onClick={() => toggleAddSportgrenModal(0,0)}>Lägg till grenkommitté</MDBBtn>
        </Box>

      );
		},
  });

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Tävlingsklasser</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
				</MDBRow>
				{(selectedSport !== null && sportDepth !== null && addSportgrenModal) ? <AddSportgrenModal sport={selectedSport} depth={sportDepth} show={addSportgrenModal} close={() => setAddSportgrenModal(false)}/> : null}
				{(selectedSport !== null && sportDepth !== null && editSportgrenModal) ? <EditSportgrenModal sport={selectedSport} depth={sportDepth} show={editSportgrenModal} close={() => setEditSportgrenModal(false)}/> : null}
				{(selectedClass !== null && addClassModal) ? <AddSportclassModal sport={selectedClass} show={addClassModal} close={() => setAddClassModal(false)}/> : null}
				{(selectedClass !== null && editClassModal) ? <EditSportclassModal sportClass={selectedClass} show={editClassModal} close={() => setEditClassModal(false)}/> : null}					
			</MDBContainer>
		</>
          );
}