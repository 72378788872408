export async function DisableSportgren(gren_id){
	
	const response = await fetch("https://dev.svsf-ta.se/api/app/sportgren/do_submit/" + gren_id, {
	//headers: headers,
	method: 'POST',
	body: JSON.stringify({
    "action": "disable_sportgren",
})
		

	});
	
	if (!response.ok) {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	}    
	const obj = await response.json();
	return  obj;
}