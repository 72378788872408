import React, { useState, useRef, useEffect, useMemo, } from 'react';
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBDatepicker,
  MDBSelect,
  MDBInputGroup,
  MDBRadio,
  MDBInput,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBAccordion,
  MDBAccordionItem,
  MDBCardSubTitle,
  MDBDatatable,
  MDBBadge,
  MDBModal,
  MDBModalHeader,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBSwitch,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBNavbar,
  MDBTooltip,
  MDBDateTimepicker,
  MDBTimepicker,
  MDBTable,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBBtnGroup,
} from 'mdb-react-ui-kit';
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { fetch_get, fetch_post, fetch_delete } from "../../../services/common";
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import {
  MRT_Table,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';


const customWeekdaysNarrow = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
const customMonthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December',];
const customWeekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
const customMonthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];


export default function EventEditStartlista({ params }) {
  // https://dev.svsf-ta.se/api/app/event/rounds/53a6c57f-377e-11ee-9385-00505692ffcc

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [data_deltagare, setDataDeltagare] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirm_replace, setConfirmReplace] = useState(null);
  const [deltagar_button, setDeltagarButton] = useState(false);
  const [lov_skjutlag, setLovSkjutlag] = useState([]);
  const [omgangar_skjutlag, setOmgangarSkjutlag] = useState([]);
  const [selected_checkbox, setSelectedCheckbox] = useState(null);


  const [rowSelection, setRowSelection] = useState({});

  const [skjutlag, setSkjutlag] = useState({ value: 0, omgang_id: 0 });

  const [activefilter, setAktivFilter] = useState(0);
  const [columnFilters, setcolumnFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);

  const [basicModalSkjutlag, setBasicModalSkjutlag] = useState(false);
  const toggleOpenSkjutlag = () => setBasicModalSkjutlag(!basicModalSkjutlag);

  const [formValue, setFormValue] = useState({});
  const [formValueSkjuttid, setFormValueSkjuttid] = useState({});

  useEffect(() => {

    fetch_get('app/event/lov-skjutlag/' + id)
      .then(data => {
        //data.data.unshift({ "value": "", "text": "-" });
        //console.log('team-lov', data.data);
        setLovSkjutlag(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

    //setOmgangarSkjutlag

    fetch_get('app/event/omgangar-skjutlag/' + id)
      .then(data => {
        //data.data.unshift({ "value": "", "text": "-" });
        //console.log('team-lov', data.data);
        setOmgangarSkjutlag(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


  }, [loading]);

  useEffect(() => {




    if (skjutlag) {
      fetch_get('app/event/skjutlag/' + id + '/' + skjutlag.value)
        .then(data => {
          setSelectedCheckbox(null);
          setData(data.data);

        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });

      fetch_get('app/event/deltagare-not-in-skjutlag/' + id + '/' + skjutlag.omgang_id)
        .then(data => {
          setDataDeltagare(data.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
    table.resetRowSelection();

  }, [skjutlag, loading]);


  const mainStyles = {
    rubriker: {
      height: 20,
      position: 'absolute',
      top: '-10px',

    },
  };

  const rows_deltagare = {
    columns: [
      { label: '', field: 'test', sort: false },
      // { label: '-', field: 'forening_name', sort: true },
    ],
    rows: data_deltagare.map((row) => {
      return {
        ...row,
        add_button: (
          <>
            <MDBBtn outline size='sm' floating className='call-btn me-4' disabled={!selected_checkbox} onClick={() => onadddeltagare(row.id, row.full_name)}>
              <MDBIcon fas icon="angle-left" />
            </MDBBtn> {row.full_name}
          </>
        ),
        test: (
          <>
            <div className='d-flex justify-content-center'>

              <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                <strong>{row.full_name}</strong>
                <span>{row.forening_name}</span>
                <span>{row.klass_name_display}</span>
              </span>
              <strong className="d-inline-flex bd-highlight flex-column">
                <MDBBtn outline size='sm' floating className='call-btn me-2 mb-2' disabled={!selected_checkbox} onClick={() => onadddeltagare(row.id, row.full_name)}>
                  <MDBIcon fas icon="angle-right" />
                </MDBBtn>

                <MDBPopover color='none'
                  btnClassName='btn btn-sm shadow-none'
                  btnChildren={<MDBIcon color='info' far icon="comment-dots" />}
                  placement='top'
                  dismiss
                  poperStyle={{ width: 450 }}
                  className='border-primary'
                >

                  <MDBPopoverBody>
                    <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
                      <span className="fw-bolder fs-5">{row.full_name}</span>
                      <span>{row.forening_name}</span>
                      <span className='text-truncate'>{row.distrikt_name}</span>
                      <span>{row.klass_name_display}</span>
                      <span className="fst-italic fw-bold">Skapad:</span>
                      <span>{row.add_date} ({row.add_by_full_name})</span>
                      <span className="fst-italic fw-bold">Kommentar/Önskemål:</span>
                      <span>{row.comment}</span>
                    </span>
                  </MDBPopoverBody>
                </MDBPopover>
              </strong>

            </div>
          </>
        ),
      };
    }),
  };

  const advancedData = {
    columns: [
      { label: ' ', field: 'delete_button', sort: false, width: 50 },
      { label: '#', field: 'tavla', sort: true, width: 20 },
      { label: 'Namn', field: 'full_name', sort: true, width: 160 },
      { label: 'Förening', field: 'forening_name', sort: true, width: 160 },
      { label: 'Klass', field: 'klass_name_display', sort: true, width: 80 },
    ],
    rows: data.map((row) => {

      return {
        ...row,
        delete_button: (
          <>
            {row.deltagare_id && (
              <MDBPopconfirm
                //outline
                //size='sm'
                //floating
                style={{ padding: '5px' }}
                color="none"
                modal
                btnChildren={<MDBIcon far icon="trash-alt" color="danger" size='sm' />}
                //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
                cancelBtnText='Avbryt'
                onConfirm={() => do_update_startlista(row.id, null)}
                cancelBtnClasses='btn-secondary'
                btnClassName='btn btn-link'
                title={"Ta bort deltagare"}
              >
                <MDBPopconfirmMessage
                  icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
                  size='lg'
                >
                  Ta bort deltagare från startlistan: <br /><br /><span className="fw-bold">{row.full_name}</span><br />
                  {row.forening_name}?<br /><br />
                  <span className="fw-bold">{row.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
                </MDBPopconfirmMessage>
              </MDBPopconfirm>

            )}
          </>
        ),
      };
    })
  };


  const reset_active_checkbox = async () => {

    // När vi byter skjutlag och checkbox är markerad, då försvinner den inte av sig själv, utan blir kvar
    // Koden nedan går igenom aktiva checkbox'ar och gör en manuell "click"
    // Denna kan behövas ändras om man har flera objekt som har tr.active, t.ex. om man har flera DataTable

    const q_table = document.querySelectorAll(".table-startlista");
    //console.log('q_table', q_table);

    // Hämtar all tr som är aktiva (den eller de checkbox'ar som är i klickade)
    const q_test = document.querySelectorAll("tr.active");
    //console.log('q_test', q_test);
    // Stegar igenom dem
    for (var i = 0, len = q_test.length; i < len; i++) {
      // Hämtar alla input:checkbox
      const list = q_test[i].querySelectorAll("input[type=checkbox]");
      // Stegar igenom dem
      for (var j = 0, len = list.length; j < len; j++) {
        // Gör en manuell "click"
        list[i].click();

        // Är det så vi har flera checkboxar, så bborde vi avbryta efter först........

      };

    };

  }

  const do_update_startlista = async (startlist_id, deltagare_id) => {

    await reset_active_checkbox();

    const form_params = {
      "action": "event_startlist_update",
      "startlist_id": startlist_id,
      "db_deltagare_id": deltagare_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    setLoading(true);


  }

  const onChangeFilter = async (f, omg) => {

    console.log('filter', f);
    table.resetRowSelection();
    setSkjutlag({ value: f, omgang_id: omg });
    setAktivFilter(f);
    if (f === 0) {
      setcolumnFilters([]);
    } else {
      setcolumnFilters([{ id: 'event_team_config_id', value: f }]);
    }

  };

  const onChangeSkjutlag = async (val) => {
    await reset_active_checkbox();
    setSkjutlag(val);
    setSelectedCheckbox(null);
  }






  const onadddeltagare = async (start_id, start_full_name) => {

    // checkbox är vald för det deltagaren ska flyttas
    if (selected_checkbox) {
      console.log('onadddeltagare selected', start_id, selected_checkbox);

      // Koll om det redan ligger en deltagare på platsen
      if (selected_checkbox.deltagare_id) {
        // Det finns redan en deltagare, då skickar vi upp en popconfirm om de vill flytta undan den.
        setConfirmReplace({
          "startlist_id": selected_checkbox.id,
          "start_full_name": start_full_name,
          "deltagare_id": start_id,
          "full_name": selected_checkbox.full_name,
          "forening_name": selected_checkbox.forening_name
        });

      } else {
        // Det finns ingen där, då kan vi flytta dit den nya direkt
        do_update_startlista(selected_checkbox.id, start_id);

      }

    } else {
      console.log('onadddeltagare', start_id);

    }
  };




  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'skjutlag', //access nested data with dot notation
        header: 'Skjutlag',
        size: 30,
        enableEditing: false,
        grow: false,
      },
      {
        accessorKey: 'tavla', //access nested data with dot notation
        header: 'Tavla2',
        size: 30,
        enableEditing: false,
        grow: false,
      },
      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            fontWeight: true ? 'bold' : 'normal',
            backgroundColor: cell.getValue() ? '#B2DFDB' : '#E1F5FE',
          },
        }),
      },


      {
        accessorKey: 'forening_name', //access nested data with dot notation
        header: 'Förening',
        size: 150,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            fontWeight: true ? 'bold' : 'normal',
            backgroundColor: cell.getValue() ? '#B2DFDB' : '#E1F5FE',
          },
        }),
      },
      {
        accessorKey: 'klass_name_display', //access nested data with dot notation
        header: 'Klass',
        size: 50,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell }) => ({
          sx: {
            fontWeight: true ? 'bold' : 'normal',
            backgroundColor: cell.getValue() ? '#B2DFDB' : '#E1F5FE',
          },
        }),
      },
    ],
    [],
  );


  const table = useMaterialReactTable({
    columns: tableColumns,
    data: data,
    enableEditing: true,
    enableColumnActions: false,
    initialState: { density: 'compact' },
    enableColumnFilters: false,
    editDisplayMode: 'cell',
    enablePagination: false,
    enableRowSelection: true,
    enableRowActions: true,

    enableMultiRowSelection: false, //use radio buttons instead of checkboxes
    getRowId: (row) => row.startlist_id, //give each row a more useful id
    muiTableBodyRowProps: ({ row }) => ({
      //add onClick to row to select upon clicking anywhere in the row
      onClick: row.getToggleSelectedHandler(),
      sx: {
        cursor: 'pointer',
        // fontWeight: true ? 'bold' : 'normal', 
        // backgroundColor: true ? 'green' : 'red',
      },
    }),


    // positionToolbarAlertBanner: 'bottom', //move the alert banner to the bottom
    positionToolbarAlertBanner: 'none',

    // onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    // onRowSelectionChange: (currentSelect, allSelected) => {           
    //
    //   console.log('cc', currentSelect, allSelected);           
    //},
    state: { rowSelection }, //pass our managed row selection state to the table to use    
    //enableRowSelection: true,
    //enableSelectAll: false,
    //enableMultiRowSelection: false,
    //positionToolbarAlertBanner: 'none',
    //
    //getRowId: (row) => row.startlist_id,
    //onRowSelectionChange: console.log('sgsdgsdfgsdgsd'),
    // state: { rowSelection },
    layoutMode: 'grid',
    // layoutMode: 'grid-no-grow',
    localization: MRT_Localization_SV,
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    renderTopToolbarCustomActions: () => (
      // <div className="d-flex flex-row mb-3">
      <>
        <MDBRow>

          {omgangar_skjutlag.map((f, k) => (
            <>
              <MDBCol size='10' className='mb-2'>
                <MDBBtnGroup aria-label='Basic example' size='sm' className="d-grid gap-2 d-md-block">
                  <MDBBtn
                    key={k} href='#'
                    outline={activefilter != f.value}
                    active={activefilter == f.value}
                    disabled
                    //  onClick={(x) => onChangeFilter(f.value)}
                    color='info'
                  >
                    {f.name}

                  </MDBBtn>


                  {f.skjutlag.map((f2, k2) => (
                    <>

                      <MDBBtn
                        key={k2} href='#'
                        outline={activefilter != f2.id}
                        active={activefilter == f2.id}
                        onClick={(x, o_id) => onChangeFilter(f2.id, f2.omgang_id)}
                        color='info'
                      >
                        {f2.skjutlag}

                      </MDBBtn>

                    </>
                  ))}

                </MDBBtnGroup>
              </MDBCol>
            </>
          ))}

        </MDBRow>

 {/* /        <MDBRow>
          <MDBCol size='10' className='mb-2'>
            <MDBSelect
              label="Välj skjutlag"
              options={omgangar_skjutlag.map((f) => ({
                text: f.name,
                value: f.value,
                options: f.skjutlag.map((f2) => ({
                  text: f2.skjutlag,
                  value: f2.id,
                  omgang_id: f2.omgang_id,
                })),
              }))}
              selected={activefilter}
              getValue={(val) => {
                const selectedOption = val[0];
                onChangeFilter(selectedOption.value, selectedOption.omgang_id);
              }}
            />
          </MDBCol>
        </MDBRow> */}
      </>
    ),
    state: { isLoading: loading },
    initialState: {
      density: 'compact',
      columnPinning: {
        right: ['mrt-row-actions'],
      },

    },
    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    renderRowActions: ({ row }) => (
      <>
        {/* <MDBTooltip tag='a' wrapperProps={{ color: 'none', className: 'ripple ripple-surface m-1' }} title="Ta bort deltager från startlistan">
          <MDBIcon far icon='trash-alt' color='danger' />
        </MDBTooltip> */}
        {row.original.deltagare_id && (
          <MDBPopconfirm
            tag='a'
            //outline
            //size='sm'
            //floating
            //style={{ padding: '5px' }}
            color="none"
            btnClassName='ripple ripple-surface m-1'
            modal
            btnChildren={<MDBIcon far icon='trash-alt' color='danger' />}
            //<MDBIcon far icon="trash-alt" color="danger" size='sm' />
            cancelBtnText='Avbryt'
            onConfirm={() => do_update_startlista(row.original.id, null)}
            cancelBtnClasses='btn-secondary'
            //btnClassName='btn btn-link'
            title={"Ta bort deltagare"}
          >
            <MDBPopconfirmMessage
              icon={<MDBIcon className='me-2' far icon="trash-alt" color='danger' />}
              size='lg'
            >
              Ta bort deltagare från startlistan: <br /><br /><span className="fw-bold">{row.original.full_name}</span><br />
              {row.original.forening_name}?<br /><br />
              <span className="fw-bold">{row.original.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
            </MDBPopconfirmMessage>
          </MDBPopconfirm>
        )}
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (

      <>
        <div>
          <div>
            <MDBBtn
              onClick={handleClick}
              size='sm'
              color='secondary'
            >
              Skriv ut / Export<MDBIcon fas icon="caret-down" className='ps-1' />
            </MDBBtn>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem dense onClick={handleClose}>
              <a href={"https://dev.svsf-ta.se/api/app/event/pdf-skjutkort/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Skjutkort</a>
            </MenuItem>
            <MenuItem dense onClick={handleClose}>
              <a href={"https://dev.svsf-ta.se/api/app/event/pdf-startlista/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Startlistalista</a>
            </MenuItem>

            <MenuItem dense onClick={handleClose}>

              <a href={"https://dev.svsf-ta.se/api/app/event/pdf-resultatlista/" + id} target="_blank"><MDBIcon fas icon="file-pdf" className='me-2' />Resultatlista</a>
            </MenuItem>
            {/* <MenuItem dense onClick={handleClose}>
						<PdfExport/>
						</MenuItem> */}
          </Menu>
        </div>
        <MRT_ToggleFullScreenButton table={table} />
        <MDBBtn
          tag='a'
          size='sm'
          // onClick={toggleOpen}
          className="rounded outline btn bg-transparent shadow-0"
        >
          <MDBIcon fas icon="info-circle" color='info' size='lg' />
        </MDBBtn>
      </>

    ),
  });

  useEffect(() => {
    // let keys = Object.keys(table.getState().rowSelection);
    let selected = table.getSelectedRowModel();
    if (selected.rows.length > 0) {
      console.log('markerad', selected.rows[0].original);
      setSelectedCheckbox(selected.rows[0].original);
    } else {
      console.log('EJ markerad');
      setSelectedCheckbox(null);
    }
    setRowSelection(table.getState().rowSelection);
  }, [table.getState().rowSelection]);

  return (
    <>

      <MDBContainer className="py-4">
        <MDBRow>
          <MDBCol md='4'>
            <EventDeltagareNotInList data_deltagare={data_deltagare} selected_checkbox={selected_checkbox}
              do_update_startlista={do_update_startlista}
              setConfirmReplace={setConfirmReplace}
              startlist_table={table}
              setLoading={setLoading}

            // onadddeltagare={onadddeltagare}
            />
          </MDBCol>
          <MDBCol md='8'>
            <MaterialReactTable
              table={table}
            />
          </MDBCol>
        </MDBRow>
        {/* <MDBNavbar light bgColor='light'>

          <MDBSelect
            style={{ width: '399px' }}
            label="Välj skjutlag"
            data={lov_skjutlag}
            preventFirstSelection
            clearBtn
            visibleOptions={8}
            onValueChange={(e) => onChangeSkjutlag(e)}
          />


          <MDBDropdown>
            <MDBDropdownToggle color='secondary'>Skriv ut / Export</MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Aktuellt lag (PDF)</MDBDropdownItem>
              <MDBDropdownItem link color='light'><MDBIcon fas icon="file-pdf" className='me-2' />Alla lag (PDF)</MDBDropdownItem>
              <MDBDropdownItem link>Sius: shooter.csv</MDBDropdownItem>
              <MDBDropdownItem link>Megalink: export.txt</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbar> */}

        {/* <MDBRow className='g-3 py-3'>
          <MDBCol className='col-md-12 col-sm-12'>
            <MDBRow className='g-3'>
              <MDBCol className='col-md-4 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Anmälda deltagare</MDBBadge>
                <MDBDatatable
                  //className='pb-4'
                  //search
                  bordered
                  //sm
                  striped
                  hover
                  maxHeight='560px'
                  sortField='full_name'
                  pagination={false}
                  entries={1000}
                  noFoundMessage={'Ingen deltagare att lägga in i skjutlagen...'}
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  data={rows_deltagare}
                />
              </MDBCol>
              <MDBCol className='col-md-7 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>

                <MDBBadge light className="position-absolute" style={mainStyles.rubriker}>Startlista</MDBBadge>

                <MDBDatatable
                  className='table-startlista'
                  //search
                  bordered
                  sm
                  striped
                  hover
                  sortField='tavla'
                  pagination={false}
                  entries={1000}
                  noFoundMessage='Välj skjutlag...'
                  loaderClass='Laddar...'
                  ofText='av'
                  rowsText='Rader per sida:'
                  selectable
                  data={advancedData}
                  // multi
                  onRowClick={(e) => {
                    console.log('onRowClick', e);
                  }}

                  onSelectRow={(e) => {
                    if (e[0]) {
                      console.log('selected', e[0].id, e)
                      setSelectedCheckbox(e[0]);
                    } else {
                      setSelectedCheckbox(null);
                    }

                  }}
                  format={(field, value) => {
                    if (field === 'full_name' || field === 'forening_name' || field === 'klass_name_display') {
                      if (value === 'null') {
                        return { backgroundColor: '#E1F5FE', fontWeight: 400 };
                      } else {
                        return { backgroundColor: '#B2DFDB', fontWeight: 400 };
                      }
                    }
                  }}
                />
              </MDBCol>


            </MDBRow>
          </MDBCol>


        </MDBRow> */}



        {/* <MDBPopconfirm
          open={confirm_replace}
          btnChildren='DEFAULT'
          modal
          btnClassName="visually-hidden"
          cancelBtnText='Avbryt'
          onConfirm={() => {
            do_update_startlista(confirm_replace.startlist_id, confirm_replace.deltagare_id,);
            setConfirmReplace(null);
          }}
          onCancel={() => setConfirmReplace(null)}
          cancelBtnClasses='btn-secondary'
          title={"zzzzzzzzzz"}
        >
          {confirm_replace && (<MDBPopconfirmMessage icon={<MDBIcon icon='question-circle' color='danger' className='me-2' />} >
            Platsen är upptagen!<br /><br />
            Vill du byta ut: <br />
            <span className="fw-bold">{confirm_replace.full_name}</span> med <br />
            <span className="fw-bold">{confirm_replace.start_full_name}</span>?<br /><br />
            <span className="fw-bold">{confirm_replace.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
          </MDBPopconfirmMessage>)}

        </MDBPopconfirm> */}


      </MDBContainer >

    </>
  );
}


//const [data_deltagare, setDataDeltagare] = useState([]);

function EventDeltagareNotInList(props) {
  const { id } = useParams();
  //const loadObject = useLoaderData();
  const [startlist_table, setstartlist_table] = useState(props.startlist_table);
  const [confirm_replace, setConfirmReplace] = useState(null);


  const do_update_startlista = async (startlist_id, deltagare_id) => {

    const form_params = {
      "action": "event_startlist_update",
      "startlist_id": startlist_id,
      "db_deltagare_id": deltagare_id
    };

    const resJson = await fetch_post('app/event/do_submit/' + id, form_params);
    props.setLoading(true);


  }

  const onadddeltagare = async (start_id, start_full_name) => {


    console.log('startlist_table', startlist_table.getState().rowSelection);

    // checkbox är vald för det deltagaren ska flyttas
    if (props.selected_checkbox) {
      console.log('onadddeltagare selected', start_id, props.selected_checkbox, start_full_name);

      // // Koll om det redan ligger en deltagare på platsen
      if (props.selected_checkbox.deltagare_id) {
        //   // Det finns redan en deltagare, då skickar vi upp en popconfirm om de vill flytta undan den.
        console.log('finns redan');
        setConfirmReplace({
          "startlist_id": props.selected_checkbox.id,
          "start_full_name": start_full_name,
          "deltagare_id": start_id,
          "full_name": props.selected_checkbox.full_name,
          "forening_name": props.selected_checkbox.forening_name
        });

      } else {
        //   // Det finns ingen där, då kan vi flytta dit den nya direkt
        console.log('ledig');
        do_update_startlista(props.selected_checkbox.id, start_id);

      }

    } else {
      console.log('onadddeltagare', start_id);

    }
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.full_name} ${row.forening_name} ${row.klass_name_display}`,
        id: 'full_name',
        header: 'Namn',
        size: 150,
        Cell: ({ cell, row }) => (
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <span className="fw-bolder">{row.original.full_name}</span>
              <span className="ms-2">{row.original.klass_name_display}</span>
            </div>
            <div>
              <span>{row.original.forening_name}</span>
            </div>
          </div>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: props.data_deltagare,
    // enableRowDragging: true,
    // onDraggingRowChange: setDraggingRow,
    // muiRowDragHandleProps: {
    //   onDragEnd: () => {
    //     if (hoveredTable === 'table-2') {
    //       setData2((data2) => [...data2, draggingRow.original]);
    //       setData1((data1) => data1.filter((d) => d !== draggingRow.original));
    //     }
    //     setHoveredTable(null);
    //   },

    // },    
    // muiTablePaperProps: {
    //   onDragEnter: () => setHoveredTable('table-1'),
    //   sx: {
    //     outline: hoveredTable === 'table-1' ? '2px dashed pink' : undefined,
    //   },
    // },
    // state: { draggingRow },
    enablePagination: false,
    localization: MRT_Localization_SV,
    enableRowActions: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableHiding: false,
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      columnPinning: {
        right: ['mrt-row-actions'],
      },

    },
    globalFilterFn: "contains",
    renderDetailPanel: ({ row }) => (
      <>
        <span className="d-inline-flex bd-highlight flex-column  flex-fill px-3">
          <span className="fw-bolder fs-5">{row.original.full_name}</span>
          <span>{row.original.forening_name}</span>
          <span className='text-truncate'>{row.original.distrikt_name}</span>
          <span>{row.original.klass_name_display}</span>
          <span className="fst-italic fw-bold">Skapad:</span>
          <span>{row.original.add_date} ({row.original.add_by_full_name})</span>
          <span className="fst-italic fw-bold">Kommentar/Önskemål:</span>
          <span>{row.original.comment}</span>
        </span>
      </>
    ),
    renderRowActions: ({ row }) => (
      <>
        <MDBTooltip
          disabled={!props.selected_checkbox}
          wrapperProps={{ tag: 'a', size: 'sm', className: 'call-btn me-2 mb-2', disabled: !props.selected_checkbox, onClick: () => onadddeltagare(row.original.id, row.original.full_name) }}
          title="Lägg till i startlistan"

        >
          <MDBIcon fas icon='angle-double-right' />
        </MDBTooltip>
      </>
    ),

  });


  return (
    <>

      <MaterialReactTable
        table={table}
      />


      <MDBPopconfirm
        open={confirm_replace}
        btnChildren='DEFAULT'
        modal
        btnClassName="visually-hidden"
        cancelBtnText='Avbryt'
        onConfirm={() => {
          do_update_startlista(confirm_replace.startlist_id, confirm_replace.deltagare_id,);
          setConfirmReplace(null);
        }}
        onCancel={() => setConfirmReplace(null)}
        cancelBtnClasses='btn-secondary'
        title={"zzzzzzzzzz"}
      >
        {confirm_replace && (<MDBPopconfirmMessage icon={<MDBIcon icon='question-circle' color='danger' className='me-2' />} >
          Platsen är upptagen!<br /><br />
          Vill du byta ut: <br />
          <span className="fw-bold">{confirm_replace.full_name}</span> med <br />
          <span className="fw-bold">{confirm_replace.start_full_name}</span>?<br /><br />
          <span className="fw-bold">{confirm_replace.full_name}</span> kommer att flyttas tillbaka till listan för anmälda.<br />
        </MDBPopconfirmMessage>)}

      </MDBPopconfirm>

    </>
  );
}

